<template>
	<div
		:class="{
			'app': true,
			'in-game': inGame,
			'is-done': atEnd,
		}"
		aria-live="polite"
	>
		<TheHomeScreen
			:title="title"
			:tagline="tagline"
			:button-text="start_text"
			@next="showDisclaimer"
		/>

		<TheDisclaimerScreen
			:content="disclaimer_html"
			@accept="maybeAskEmployeeInfo"
			@reject="goToScreen( 'home' )"
		/>

		<TheEmployeeInfoScreen
			v-if="ask_site_id || ask_experience"
			:ask-site-id="ask_site_id"
			:ask-experience="ask_experience"
			@submit="chooseAvatar"
		/>

		<TheAvatarScreen
			@next="startGame"
		/>

		<GameScreen
			v-for="question in mainQuestions"
			:key="question.id"
			v-bind="question"
			class="is-type-main"
			:offer-correction="accept_corrections"
			@answer="maybeAddRepeatQuestion"
			@done="nextMainQuestion"
		/>

		<GameScreen
			v-for="question in recoveryQuestions"
			:key="question.id"
			v-bind="question"
			class="is-type-recovery"
			@answer="maybeAbortRecovery"
			@done="nextRecoveryQuestion"
		/>

		<GameScreen
			v-for="question in interruptQuestions"
			:key="question.id"
			v-bind="question"
			class="is-type-interrupt"
			:offer-correction="accept_corrections"
			skippable
			@done="nextMainQuestion"
		/>

		<TheDemographicSurvey
			v-if="demographic_survey"
			v-bind="demographic_survey"
			@skip="goToScreen( 'end' )"
			@done="goToScreen( 'end' )"
		/>

		<TheEndScreen
			:level="level"
			:result="scoreResult"
			:with-sharing="allow_sharing"
			:with-resources="!! resources_html"
			@restart="restart"
		/>

		<TheLevelScreen
			:level="level"
			:offer-recover="canDoRecovery"
			:did-recover="didRecoverLevel"
			:recover-notice="recovery_notice"
			@recover="startRecoveryQuestions"
			@done="nextMainQuestion"
			@claim="goToScreen( 'reward' )"
		/>

		<TheRewardScreen
			:level="level"
			:options="rewards"
			@done="nextMainQuestion"
		/>

		<TheRecoveryNoticeScreen
			name="recoveryNotice"
			:text="recovery_notice"
			@done="startRecoveryQuestions"
		/>

		<TheShareScreen
			v-if="allow_sharing"
			:level="level"
			:text="share_text"
			:sites="share_options"
		/>

		<TheAppToolbar
			:in-game="inGame"
			:privacy-policy-url="privacy_policy_url"
			:terms-of-use-url="terms_of_use_url"
		/>

		<TheMenuModal
			:about="about_html"
			:privacy-policy="privacy_policy_html"
			:terms-of-use="terms_of_use_html"
		/>

		<AppModal
			v-if="privacy_policy_html"
			name="privacypolicy"
			class="privacypolicy"
			:content="privacy_policy_html"
		/>

		<AppModal
			v-if="terms_of_use_html"
			name="termsofuse"
			class="termsofuse"
			:content="terms_of_use_html"
		/>

		<AppModal
			v-if="resources_html"
			name="resources"
			class="resources"
			:content="resources_html"
		/>

		<PortalTarget :name="currentScreen" />

		<TheLandscapeNotice v-if="inGame" />
	</div>
</template>

<script>
/* eslint-disable vue/prop-name-casing */

import { mapState, mapMutations } from 'vuex';
import { Howl } from 'howler';
import shuffleArray from 'shuffle-array';

import TheAppToolbar from './components/TheAppToolbar';
import TheMenuModal from './components/TheMenuModal';
import TheHomeScreen from './components/TheHomeScreen';
import TheAvatarScreen from './components/TheAvatarScreen';
import TheDisclaimerScreen from './components/TheDisclaimerScreen';
import TheEmployeeInfoScreen from './components/TheEmployeeInfoScreen';
import GameScreen from './components/GameScreen';
import TheDemographicSurvey from './components/TheDemographicSurvey';
import TheEndScreen from './components/TheEndScreen';
import TheLevelScreen from './components/TheLevelScreen';
import TheRewardScreen from './components/TheRewardScreen';
import TheRecoveryNoticeScreen from './components/TheRecoveryNoticeScreen';
import TheShareScreen from './components/TheShareScreen';
import TheLandscapeNotice from './components/TheLandscapeNotice';

function shuffle( array ) {
	return shuffleArray( array, { copy: true } );
}

export default {
	components: {
		TheAppToolbar,
		TheMenuModal,
		TheHomeScreen,
		TheAvatarScreen,
		TheDisclaimerScreen,
		TheEmployeeInfoScreen,
		GameScreen,
		TheDemographicSurvey,
		TheEndScreen,
		TheLevelScreen,
		TheRewardScreen,
		TheRecoveryNoticeScreen,
		TheShareScreen,
		TheLandscapeNotice,
	},
	provide() {
		return {
			parentView: false,
			showUUID: this.show_uuid,
			offerTimers: this.use_timers,
			getLevel: () => this.level,
			getMaxLevel: () => this.maxLevel,
		};
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		tagline: {
			type: String,
			default: null,
		},
		start_text: {
			type: String,
			required: true,
		},
		about_html: {
			type: String,
			required: true,
		},
		disclaimer_html: {
			type: String,
			required: true,
		},
		share_options: {
			type: Array,
			required: true,
		},
		share_text: {
			type: String,
			required: true,
		},
		main_questions: {
			type: Array,
			required: true,
		},
		pregame_questions: {
			type: Array,
			default: () => [],
		},
		postgame_questions: {
			type: Array,
			default: () => [],
		},
		followup_interfaces: {
			type: Array,
			required: true,
		},
		recovery_questions: {
			type: Array,
			required: true,
		},
		interrupt_questions: {
			type: Array,
			required: true,
		},
		demographic_survey: {
			type: Object,
			default: null,
		},
		rewards: {
			type: Array,
			required: true,
		},
		recovery_notice: {
			type: String,
			default: null,
		},
		privacy_policy_url: {
			type: String,
			default: '',
		},
		terms_of_use_url: {
			type: String,
			default: '',
		},
		privacy_policy_html: {
			type: String,
			default: '',
		},
		terms_of_use_html: {
			type: String,
			default: '',
		},
		resources_html: {
			type: String,
			default: '',
		},

		shuffle_main_questions: Boolean,
		shuffle_recovery_questions: Boolean,
		shuffle_interrupt_questions: Boolean,
		leading_main_questions: {
			type: Number,
			default: 0,
		},
		repeat_first_wrong_question: {
			type: Number,
			default: 0,
		},
		repeat_limit: {
			type: Number,
			default: 1,
		},
		reward_correct_repeat_questions: Boolean,
		points_per_level: {
			type: [ Number, Array ],
			default: 2,
		},
		interrupt_placement: {
			type: [ Number, Array ],
			default: 5,
		},
		recovery_limit: {
			type: Number,
			default: 0,
		},
		allow_repeat_skip: Boolean,
		accept_corrections: Boolean,
		allow_sharing: Boolean,
		user_timers: Boolean,
		ask_site_id: Boolean,
		ask_experience: Boolean,

		music_source: {
			type: String,
			default: null,
		},

		show_uuid: {
			type: [ Boolean, Number ],
			default: false,
		},
	},
	data() {
		let pointsPerLevel;
		if ( this.points_per_level instanceof Array ) {
			pointsPerLevel = this.points_per_level.map( v => parseInt( v ) );
		} else {
			pointsPerLevel = parseInt( this.points_per_level );
		}

		let interruptPlacement;
		if ( this.interrupt_placement instanceof Array ) {
			interruptPlacement = this.interrupt_placement.map( v => parseInt( v ) );
		} else {
			interruptPlacement = parseInt( this.interrupt_placement );
			// 1 question = 1 specific position
			if ( this.interrupt_questions.length === 1 ) {
				interruptPlacement = [ interruptPlacement ];
			}
		}

		return {
			mainQuestions: [],
			recoveryQuestions: [],
			allRecoveryQuestions: [],
			interruptQuestions: null,

			pointsPerLevel,
			interruptPlacement,

			currentMainQuestion: -1,
			currentRecoveryQuestion: 0,
			currentInterruptQuestion: 0,
			didInterruptQuestion: false,
			usedRecoveryQuestions: 0,

			currentLevel: 0,
			recoveryCount: 0,
			repeatCount: 0,
			repeatOffset: this.repeat_first_wrong_question,
			didRecoverLevel: false,
			didRecoveryNotice: false,
			didSurveyInvite: false,

			atEnd: false,
		};
	},
	computed: {
		...mapState( [
			'currentScreen',
			'answers',
			'score',
			'trueScore',
			'useAudio',
			'useMusic',
		] ),

		level() {
			let level = 0;

			// Level is based on consistent per-point basis
			if ( typeof this.pointsPerLevel === 'number' ) {
				level = Math.floor( this.score / this.pointsPerLevel );
			} else {
				// Level is based on arbitrary score thresholds
				for ( let i = 0; i < this.pointsPerLevel.length; i++ ) {
					if ( this.score >= this.pointsPerLevel[ i ] ) {
						level = i + 1;
					} else {
						break;
					}
				}
			}

			// +1 if perfect trueScore
			if ( this.trueScore === this.main_questions.length ) {
				level++;
			}

			return level;
		},

		maxLevel() {
			let max = 0;
			if ( typeof this.pointsPerLevel === 'number' ) {
				max = Math.floor( this.main_questions.length / this.pointsPerLevel );
			} else {
				max = this.pointsPerLevel.length;
			}

			return max + 1; // for perfect score
		},

		scoreResult() {
			if ( this.level <= 3 ) {
				return 'fail';
			}

			if ( this.trueScore === this.main_questions.length ) {
				return 'perfect';
			}

			return 'average';
		},

		inGame() {
			return this.currentScreen !== 'home';
		},

		canDoRecovery() {
			// Limit = 0 = disabled
			if ( ! this.recovery_limit ) {
				return false;
			}

			// Limit > 0 = compare with count, abort if exceeded
			if ( this.recovery_limit > 0 && this.recoveryCount >= this.recovery_limit ) {
				return false;
			}

			// Do we have questions to use?
			return this.allRecoveryQuestions.length > 0 || this.followup_interfaces.length > 0;
		},

		shouldShowInterruptQuestion() {
			// Not if we have no placement rules
			if ( ! this.interruptPlacement ) {
				return false;
			}

			// Not if we have no more left
			if ( this.currentInterruptQuestion >= this.interruptQuestions.length ) {
				return false;
			}

			// If interrupt_placement is interval, check that
			if ( typeof this.interruptPlacement === 'number' ) {
				// max(1, c) because game starts with currentMainQuestion = -1
				return Math.max( 1, this.currentMainQuestion + 1 ) % this.interruptPlacement === 0;
			}

			// Otherwise check if we're on the next placement
			return this.currentMainQuestion + 1 === this.interruptPlacement[ this.currentInterruptQuestion ];
		},
	},
	watch: {
		useAudio( status ) {
			if ( ! this.music && this.music_source ) {
				this.music = new Howl( {
					src: [ this.music_source ],
					html5: true,
					loop: true,
				} );
			}

			if ( this.music ) {
				// Play if enabled and not already
				if ( status && ! this.music.playing() && this.useMusic ) {
					this.music.play();
				}

				// Mute if either audio or music is false, otherwise unmute
				this.music.mute( ! status || ! this.useMusic );
			}
		},
		useMusic( status ) {
			if ( this.music ) {
				// Mute if either audio or music is false, otherwise unmute
				this.music.mute( ! status || ! this.useAudio );
			}
		},
	},
	created() {
		this.setup();
	},
	methods: {
		...mapMutations( [
			'goToScreen',
			'incrementScore',
		] ),

		...mapMutations( {
			resetState: 'reset',
		} ),

		setup() {
			this.$log.start();
			this.goToScreen( 'home' );

			let mainQuestions;
			if ( this.shuffle_main_questions ) {
				if ( this.leading_main_questions ) {
					mainQuestions = this.main_questions.slice( 0, this.leading_main_questions )
						.concat( shuffle( this.main_questions.slice( this.leading_main_questions ) ) );
				} else {
					mainQuestions = shuffle( this.main_questions );
				}
			} else {
				mainQuestions = this.main_questions;
			}

			const makeSkippable = question => ( {
				...question,
				skippable: true,
			} );

			this.mainQuestions = [
				...this.pregame_questions.map( makeSkippable ),
				...mainQuestions,
				...this.postgame_questions.map( makeSkippable ),
			];

			if ( this.shuffle_recovery_questions ) {
				this.allRecoveryQuestions = shuffle( this.recovery_questions );
			} else {
				this.allRecoveryQuestions = [ ...this.recovery_questions ];
			}

			if ( this.shuffle_interrupt_questions ) {
				this.interruptQuestions = shuffle( this.interrupt_questions );
			} else {
				this.interruptQuestions = [ ...this.interrupt_questions ];
			}
		},

		goToMainQuestion( index ) {
			if ( typeof index === 'number' && index < this.mainQuestions.length ) {
				this.currentMainQuestion = index;
				this.goToScreen( this.mainQuestions[ index ].id );
			} else {
				this.currentMainQuestion = null;

				this.atEnd = true;
				if ( this.demographic_survey ) {
					this.showModal( 'survey-invite' );
				} else {
					this.goToScreen( 'end' );
				}
			}
		},
		nextMainQuestion() {
			// If level changed, short circuit and go to
			// LevelChange screen instead, next call will
			// proceed normally
			if ( this.currentLevel !== this.level ) {
				this.didRecoverLevel = false;
				this.logRankChange();
				this.goToScreen( 'levelChange' );
				this.currentLevel = this.level;
				return;
			}

			// If on the interrupt interval, short circuit and
			// setup an interrupt question, next call will unset
			// it and proceed normally
			if ( this.shouldShowInterruptQuestion && ! this.didInterruptQuestion ) {
				this.nextInterruptQuestion();
				return;
			}
			this.didInterruptQuestion = false;

			this.goToMainQuestion( this.currentMainQuestion + 1 );
		},

		maybeAddRepeatQuestion( result ) {
			// Get current question
			const original = this.mainQuestions[ this.currentMainQuestion ];

			// If not a wrong answer, a repeat, or repeats are not supported/exhausted, skip
			if ( result !== false || original.isRepeat || ! this.repeat_first_wrong_question || this.repeatCount >= this.repeat_limit ) {
				return;
			}

			// Determine the position the repeat would go
			const repeatPosition = this.currentMainQuestion + this.repeatOffset;

			// If there's enough space, insert a copy, as a repeat
			if ( repeatPosition < this.mainQuestions.length ) {
				const redoQuestion = {
					...original,
					id: `repeat-${ original.id }`,
					isRepeat: true,
					skippable: this.allow_repeat_skip,
					affectsScore: !! this.reward_correct_repeat_questions,
				};

				// Insert this repeated question 5 entries ahead
				this.mainQuestions.splice( repeatPosition, 0, redoQuestion );

				// Mark as having added a repeat
				this.repeatCount++;
				this.repeatOffset++;
			}
		},

		startRecoveryQuestions() {
			this.recoveryCount++;
			if ( ! this.didRecoveryNotice && this.recovery_notice ) {
				this.goToScreen( 'recoveryNotice' );
				this.didRecoveryNotice = true;
				return;
			}

			// Create followup questions based on the question they got wrong
			const currentMainQuestion = this.mainQuestions[ this.currentMainQuestion ];
			this.recoveryQuestions = this.followup_interfaces.map( ( { id: followupId, ...followupConfig } ) => {
				return {
					id: currentMainQuestion.id + followupId,
					name: currentMainQuestion.name,
					title: followupConfig.title,
					text: currentMainQuestion.correct_text,
					control: followupConfig,
				};
			} );

			// Add the next general recovery question if any are left
			if ( this.usedRecoveryQuestions < this.allRecoveryQuestions.length ) {
				this.recoveryQuestions.push( this.allRecoveryQuestions[ this.usedRecoveryQuestions ] );
				this.usedRecoveryQuestions++;
			}

			// Go to the first recovery question
			setTimeout( () => this.goToRecoveryQuestion( 0 ) );
		},
		goToRecoveryQuestion( index ) {
			if ( typeof index === 'number' && index < this.recoveryQuestions.length ) {
				this.currentRecoveryQuestion = index;
				this.goToScreen( this.recoveryQuestions[ index ].id );
			} else {
				this.currentRecoveryQuestion = null;

				// If recovery questions weren't aborted/cleared,
				// re-increment their score
				if ( this.recoveryQuestions.length > 0 ) {
					this.incrementScore();
					this.logRankChange( 'recover' );
					this.currentLevel = this.level;
					this.didRecoverLevel = true;
					this.goToScreen( 'levelChange' );
				} else {
					// If aborted, just move onto next question
					this.nextMainQuestion();
				}
			}
		},
		maybeAbortRecovery( result, answer ) {
			if ( answer === 'abstain' ) {
				// Clear recovery questions list so nextRecoveryQuestion() returns to level screen
				this.recoveryQuestions = [];

				// Restore previously used recovery question
				if ( this.usedRecoveryQuestions > 0 ) {
					this.usedRecoveryQuestions--;
				}
			}
		},
		nextRecoveryQuestion() {
			this.goToRecoveryQuestion( this.currentRecoveryQuestion + 1 );
		},

		nextInterruptQuestion() {
			this.goToScreen( this.interruptQuestions[ this.currentInterruptQuestion ].id );
			this.currentInterruptQuestion++;
			this.didInterruptQuestion = true;
		},

		logRankChange( type ) {
			if ( ! type ) {
				type = this.level > this.currentLevel ? 'up' : 'down';
			}

			// Log the rank change
			this.$log.event( {
				event_type: 'rank_' + type,
				target: String( this.level ),
				question_name: this.mainQuestions[ this.currentMainQuestion ].name,
			} );
		},

		maybeAskEmployeeInfo() {
			if ( this.ask_site_id || this.ask_experience ) {
				this.goToScreen( 'employeeinfo' );
			} else {
				this.chooseAvatar();
			}
		},

		chooseAvatar() {
			this.goToScreen( 'avatar' );
		},

		showDisclaimer() {
			this.goToScreen( 'disclaimer' );
		},

		startGame() {
			this.nextMainQuestion( 0 );
		},

		restart() {
			this.resetState();
			this.setup();

			this.currentMainQuestion = -1;
			this.currentRecoveryQuestion = 0;
			this.currentInterruptQuestion = 0;
			this.didInterruptQuestion = false;
			this.usedRecoveryQuestions = 0;
			this.currentLevel = 0;
			this.recoveryCount = 0;
			this.repeatCount = 0;
			this.repeatOffset = this.repeat_first_wrong_question;
			this.atEnd = false;
		},
	},
};
</script>
