<template>
	<AppModal
		class="readmore"
		:name="id"
	>
		<div class="readmore__header">
			<h2>{{ $l10n( 'details' ) }}</h2>
		</div>

		<div class="readmore__body">
			<div
				v-if="content"
				v-html="content"
			/>

			<p
				v-if="source"
			>
				<strong>{{ $l10n( 'source' ) }}</strong>
				{{ source }}
			</p>

			<AppButton
				v-if="offerCorrection"
				name="correction"
				:question="context"
				color="green"
				@click="showModal( `${ context }-correction` )"
			>
				{{ $l10n( 'offer-correction' ) }}
			</AppButton>
		</div>
	</AppModal>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		context: {
			type: String,
			required: true,
		},
		preface: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			default: null,
		},
		source: {
			type: String,
			default: '',
		},
		sourceUrl: {
			type: String,
			default: '',
		},
		offerCorrection: Boolean,
	},
};
</script>

<style lang="scss">
.readmore {
	&__header {
		margin-bottom: 1em;

		.button {
			margin: rem(28) auto 0;
		}
	}
	&__body {
		border-top: 1px solid $color-light;
		margin-top: 1em;
		padding-top: 1em;
	}
}
</style>
