<template>
	<AppScreen
		name="end"
		:class="[ 'endscreen', 'is-level-' + ( level ) ]"
		:transition="{
			name: 'build',
			duration: 2000,
		}"
	>
		<div class="endscreen__header">
			<StarGraphic
				:number="level"
				class="endscreen__star"
			/>

			<h2>{{ finishTitle }}</h2>
			<p>{{ finishDetail }}</p>

			<p v-if="showUUID && session">
				{{ $l10n( 'your-uuid' ) }}
				<code class="endscreen__code">{{ session }}</code>
			</p>

			<div class="button-group">
				<AppButton
					v-if="withResources"
					name="show-resources"
					location="end"
					with-text
					@click="showModal( 'resources' )"
				>
					{{ $l10n( 'resources' ) }}
				</AppButton>

				<AppButton
					v-if="withSharing"
					name="share"
					location="end"
					icon="share"
					with-text
					@click="shareLevel"
				>
					{{ $l10n( 'share-level' ) }}
				</AppButton>

				<AppButton
					v-if="rewards.length"
					name="restart"
					location="end"
					is-secondary
					icon="trophy"
					with-text
					@click="showModal( 'menu' ), goToTab( 'rewards' )"
				>
					{{ $l10n( 'my-rewards' ) }}
				</AppButton>
			</div>
		</div>
		<div
			class="endscreen__graphic"
			aria-hidden="true"
		>
			<AppCharacter
				:type="avatar.name"
				:color="avatar.color"
				animation="reaction"
				:clip="animation"
			/>
		</div>
		<div class="endscreen__footer">
			<AppButton
				name="restart"
				location="end"
				is-primary
				@click="$emit( 'restart' )"
			>
				{{ $l10n( 'restart' ) }}
			</AppButton>
		</div>
	</AppScreen>
</template>

<script>
import { mapState } from 'vuex';
import StarGraphic from './StarGraphic';

const animations = {
	fail: 'hmm',
	average: 'happy',
	perfect: 'reward',
};

export default {
	components: {
		StarGraphic,
	},
	inject: [ 'showUUID' ],
	props: {
		level: {
			type: Number,
			required: true,
		},
		result: {
			type: String,
			default: '',
		},
		withSharing: Boolean,
		withResources: Boolean,
	},
	computed: {
		...mapState( [ 'avatar', 'rewards' ] ),

		finishTitle() {
			return this.$l10n( `finish-title-${ this.result }` );
		},
		finishDetail() {
			return this.$l10n( `finish-detail-${ this.result }` );
		},

		session() {
			const session = this.$store.state.session;

			if ( this.showUUID === true ) {
				return session;
			} else
			if ( this.showUUID ) {
				return session.substr( -1 * this.showUUID );
			}

			return false;
		},
		animation() {
			return animations[ this.result ];
		},
	},
	methods: {
		shareLevel() {
			if ( typeof navigator.share === 'undefined' ) {
				this.showModal( 'share' );
				return;
			}

			const level = this.level;
			const data = {
				url: window.location.origin + this.$store.state.baseURL + `l${ level }.html`,
				text: this.$l10n( 'share-rank', this.$l10n( level ) ),
			};

			navigator.share( data ).catch( ( error ) => {
				// Show fallback unless they manually cancelled
				if ( error.name !== 'AbortError' ) {
					this.showModal( 'share' );
				}
			} );
		},
	},
};
</script>

<style lang="scss">
@keyframes endStarScale {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}

@keyframes endStarSpin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.endscreen {
	align-items: stretch;

	&__header {
		flex: 0 1 50%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		text-align: center;
		margin-bottom: rem(30);

		h2 {
			margin-bottom: .25em;
		}

		p {
			margin-bottom: 1em;
		}

		.build-enter-active & {
			animation: slideInTop .4s ease-out both;
		}
		.build-leave-active & {
			animation: slideOutTop .4s ease-out both;
		}
	}
		&__star {
			width: rem(112);
			margin: 0 auto 30px;
			animation: endStarScale .5s .5s ease-out both;

			.star__icon {
				animation: endStarSpin 1s .5s ease-out both;
			}

			.star__label {
				font-size: rem(54);
			}

			@include breakpoint($min-height: 600) {
				width: rem(140);

				.star__label {
					font-size: rem(64);
				}
			}
		}
		&__code {
			display: block;
			font-family: 'Courier New', Courier, monospace;
			font-weight: bold;
			margin-top: .2em;
		}

	&__graphic {
		position: relative;
		flex: 1;
		max-height: 170px;
		margin-top: auto;

		.character {
			position: absolute;
			top: 0;
			left: 0;
			width: 200px;
			transform: translateX(-10%) rotate(24deg);
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .4s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}

		@include breakpoint($min-height: 600) {
			.character {
				width: 240px;
			}
		}
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		> :not(:first-child) {
			margin-left: 1em;
		}

		.build-enter-active & {
			animation: slideInBottom .4s ease-out both;
			animation-delay: .8s;
		}
		.build-leave-active & {
			animation: slideOutBottom .4s ease-out both;
		}
	}
}
</style>
