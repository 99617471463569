/* eslint-disable camelcase */

import Vue from 'vue';
import PortalVue from 'portal-vue';
import store from './store';
import { mapMutations } from 'vuex';
import { Howler } from 'howler';

Vue.config.productionTip = false;

import fixText from './utilities/text.js';
import l10n from './utilities/l10n.js';
import baseUrl from './utilities/url.js';
import logging from './utilities/logging.js';
import sounds from './utilities/sounds.js';

const BASE_URL = process.env.VUE_APP_BASE_URL || process.env.BASE_URL;
const LOGGING_URL = process.env.VUE_APP_LOGGING_URL || process.env.LOGGING_URL;

import {
	name as surveyName,
	config,
	translations,
	main_questions,
	pregame_questions,
	postgame_questions,
	followup_interfaces,
	recovery_questions,
	interrupt_questions,
	demographic_survey,
	rewards,
} from './config.js';

Vue.use( PortalVue );
Vue.use( fixText );
Vue.use( l10n, translations );
Vue.use( baseUrl, BASE_URL );
Vue.use( logging, LOGGING_URL, surveyName, store );
Vue.use( sounds, BASE_URL + 'effects.mp3', [
	[ 'drop', '0:00.000', '0:00.999' ],
	[ 'pickup', '0:01.000', '0:01.999' ],
	[ 'level-up', '0:02.000', '0:04.999' ],
	[ 'level-down', '0:05.000', '0:07.999' ],
	[ 'correct', '0:08.000', '0:11.999' ],
	[ 'click', '0:12.000', '0:12.999' ],
	[ 'incorrect', '0:13.000', '0:13.999' ],
] );

Vue.mixin( {
	methods: mapMutations( [ 'showModal', 'hideModal', 'goToTab' ] ),
} );

import './assets/styles.scss';

import ScreenReaderText from './components/ScreenReaderText';
import AppGraphic from './components/AppGraphic';
import AppImage from './components/AppImage';
import AppButton from './components/AppButton';
import AppModal from './components/AppModal';
import AppScreen from './components/AppScreen';
import AppTooltip from './components/AppTooltip';

Vue.component( 'ScreenReaderText', ScreenReaderText );
Vue.component( 'AppGraphic', AppGraphic );
Vue.component( 'AppImage', AppImage );
Vue.component( 'AppButton', AppButton );
Vue.component( 'AppModal', AppModal );
Vue.component( 'AppScreen', AppScreen );
Vue.component( 'AppTooltip', AppTooltip );

store.state.baseURL = BASE_URL;
store.state.useTimers = config.use_timers ?? false;

// Default to muted until enabled
Howler.mute( store.state.useAudio );

// Handle auto-muting when focus leaves/returns
let hiddenProp, visibilityChange;
if ( typeof document.hidden !== 'undefined' ) { // Opera 12.10 and Firefox 18 and later support
	hiddenProp = 'hidden';
	visibilityChange = 'visibilitychange';
} else
if ( typeof document.msHidden !== 'undefined' ) {
	hiddenProp = 'msHidden';
	visibilityChange = 'msvisibilitychange';
} else
if ( typeof document.webkitHidden !== 'undefined' ) {
	hiddenProp = 'webkitHidden';
	visibilityChange = 'webkitvisibilitychange';
}

let leaveMuted = ! store.state.useAudio;
document.addEventListener( visibilityChange, function() {
	if ( document[ hiddenProp ] ) {
		leaveMuted = ! store.state.useAudio;
		store.commit( 'disableAudio' );
	} else
	if ( ! leaveMuted ) {
		store.commit( 'enableAudio' );
	}
} );

document.addEventListener( 'touchstart', function() {
	store.state.isTouchDevice = true;
}, false );

if ( config.allow_sharing ) {
	const script = document.createElement( 'script' );
	script.async = true;
	script.src = 'https://platform-api.sharethis.com/js/sharethis.js';
	document.head.appendChild( script );
}

import App from './App.vue';

Vue.component( 'AppCharacter', () => import( '@/components/AppCharacter.vue' ) );

new Vue( {
	store,
	render: h => h( App, {
		props: {
			...config,
			main_questions,
			pregame_questions,
			postgame_questions,
			followup_interfaces,
			recovery_questions,
			interrupt_questions,
			demographic_survey,
			rewards,
			music_source: BASE_URL + 'music.mp3',
		},
	} ),
} ).$mount( '#app' );
